import styled from 'styled-components';

export const ChatContainer = styled.div`
  display: flex;
  margin: 50px 20px 40px 50px;
`;

export const Container = styled.div`
  position: relative;
  width: 70%;
  height: auto;
  min-height: 110px;
  padding: 20px 20px 40px 20px;
  margin: 0px 10px 0px 30px;
  box-sizing: border-box;
  background: #fff;
  font-family: 'Raleway', sans-serif;
  border-left: 10px solid #ced6de;
  box-shadow: 0px 4px 11.83px 1.17px rgba(109, 120, 148, 0.13);
`;

export const Avatar = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: black;
  border: 4px solid #fff;
  box-shadow: 0px 4px 11.83px 1.17px rgba(109, 120, 148, 0.13);
`;

export const Rabinho = styled.img`
  width: 20px;
  position: absolute;
  left: -30px;
  top: 46%;
`;

export const Text = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 1.2em;
  line-height: 23px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;
