import styled from "styled-components";
import {Button} from 'reactstrap';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
  width: 100%;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}
`;

export const Input = styled.input`
	width: 100%;
	height: 58px;
	font-size: 1.3em;
	padding-left: 10px;
	margin-bottom: 10px;
	border: 1px solid ${props => (props.error ? "red" : "#ced6de")};
	border-radius: 4px;
	box-sizing: border-box;
	box-shadow: 0px 4px 11.83px 1.17px rgba(109, 120, 148, 0.13);
`;

export const Select = styled.select`
	width: 100%;
	height: 58px;
	background: #fff;
	font-size: 1.3em;
	padding-left: 10px;
	margin-bottom: 10px;
	border: 1px solid ${props => (props.error ? "red" : "#ced6de")};
	border-radius: 4px;
	box-sizing: border-box;
	box-shadow: 0px 4px 11.83px 1.17px rgba(109, 120, 148, 0.13);
`;

export const Error = styled.p`
	color: red;
	font-family: "Raleway", sans-serif;
	margin-top: 4px;
	margin-bottom: 10px;
`;

export const BotContainer = styled.div`
	height: 100%;
	background: #006633 !important;
     
      background: -moz-linear-gradient(top, #006633 2%, #349937 100%) !important;   
      background: -webkit-linear-gradient(top, #006633 2%, #349937 100%) !important;  
      background: linear-gradient(to bottom, #006633 2%, #349937 100%) !important;   
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006633', endColorstr='#349937', GradientType=0) !important;
  ${'' /* cor antiga 00cbbd */}
	@media only screen and (max-width: 600px) {
		display: none;
	}
`;

export const FormContainer = styled.div`
	width: 38.2%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #fff;
	@media only screen and (max-width: 600px) {
		width: 100%;
		height: 100%;
		justify-content: flex-start;
	}
`;

export const InputsContainer = styled.div`
	width: 500px;
	div img {
		display: none;
	}
	@media (max-width: 1380px) {
		width: 380px;
	}
	@media (max-width: 600px) {
		width: 280px;
		height: 20px;
		div img {
			display: block;
			margin: 20px 0 20px;
		}
	}
`;

export const Title = styled.h2`
	color: #121a3b;
	font-size: 3.4em;
	font-family: "Raleway", sans-serif;
	font-weight: bold;
	margin-top: 0px;
	margin-bottom: 25px;
	@media (max-width: 1380px) {
		font-size: 2.5em;
	}
	@media only screen and (max-width: 600px) {
		font-size: 1.5em;
	}
`;

export const Message = styled.h3`
	color: red;
	font-size: 1.4em;
	font-family: "Raleway", sans-serif;
	font-weight: bold;
	margin-top: 0px;
	margin-bottom: 25px;
	@media (max-width: 1380px) {
		font-size: 2.5em;
	}
	@media only screen and (max-width: 600px) {
		font-size: 1.2em;
	}
`;

export const Defensoria = styled.h5`
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: "Raleway", sans-serif;
	color: #121a3b;
	line-height: 26px;
	span {
		font-weight: bold;
	}
	@media only screen and (max-width: 600px) {
		line-height: 21px;
	}
`;

export const AButton = styled(Button)`
	&& {
		width: 100%;
		color: #fff;
		height: 50px;
		background: #006633; 
    ${'' /* cor antiga 00cbbd */}
		border-radius: 8px;
		margin-top: 25px;
		&:hover {
			background: #349937;
		}
	}
`;

export const Header = styled.div`
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;
