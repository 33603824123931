import styled from "styled-components";

export const Logo = styled.img`
  width: 230px;
`;

export const MenuContainer = styled.div`
  margin: 0 80px 0 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Raleway", sans-serif;
  background: transparent;
`;

export const MenuLeft = styled.div`
  padding: 40px;
`;

export const MenuRight = styled.div`
  display: flex;
  padding: 40px;
`;

export const MenuItem = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #73b7bf;
  padding: 10px;
`;

export const Container = styled.div`
  display: flex;
  max-height: 100vh;
  flex-direction: row;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Atendimento = styled.img`
  position: absolute;
  width: 36%;
  right: 8%;
  top: -10px;
`;

export const TitleContainer = styled.div`
  margin: 140px;
`;

export const Title = styled.h1`
  width: 60%;
  font-size: 5em;
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  background: linear-gradient(to right, #7dcad6, #a6ece3);
  background: -webkit-linear-gradient(to right, #7dcad6, #a6ece3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 1366px) {
    font-size: 4em;
  }
  @media (max-width: 1280px) {
    font-size: 3.5em;
  }
  @media (max-width: 768px) {
    font-size: 3em;
  }
`;

export const Subtitle = styled.p`
  width: 56%;
  color: #68a4ad;
  font-size: 1.6em;
  font-family: "Raleway", sans-serif;
  margin-top: 10px;
  line-height: 30px;
  @media (max-width: 1366px) {
    font-size: 1.5em;
  }
  @media (max-width: 1280px) {
    font-size: 1.3em;
  }
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

export const LeftImg = styled.img`
  width: 50%;
  height: 100vh;
  position: absolute;
  top: -140px;
  z-index: -1;
`;

export const Button = styled.button`
  width: 300px;
  height: 60px;
  margin-top: 20px;
  font-family: "Raleway", sans-serif;
  font-size: 1.4em;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  background: #7dc2d6;
  &:active {
    background: #6dacbe;
    border-bottom: 4px solid #34525b;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
