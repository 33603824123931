import React, { useState, useEffect } from "react";
//import moment from "moment";
import { useParams,useLocation } from "react-router-dom";
import ChatMessage from "../../components/ChatMessage";
import { Header, BotContainer} from "./styles";
import { Bold, Text } from "../../components/ChatMessage/styles";
import Logo from "../../assets/logo.png";
import { useStateMachine } from "little-state-machine";
import updateAction from "../../updateAction";
import NomeSocial from "./StepForms/NomeSocial";
//import NomeSocial2 from "./StepForms/NomeSocial2";
import NomeSocial3 from "./StepForms/NomeSocial3";
//import RendaFamiliar from "./StepForms/RendaFamiliar";
//import DadosPessoais from "./StepForms/DadosPessoais";
//import Endereco from "./StepForms/Endereco";
//import Senha from "./StepForms/Senha";
//import Contato from "./StepForms/Contato";
import { Container, Row, Col } from 'reactstrap';
export default function Cadastro() {
	const { action, state } = useStateMachine(updateAction);
	let { id } = useParams();
	const [comps, setComp] = useState([]);
  const location = useLocation();
	useEffect(() => {
		setPrimeiro();
	}, []);


	


	function Primeiro() {
		return (
			<>
				<ChatMessage>
					<Text>
						Oi, eu me chamo
						<Bold> Ana! </Bold>
						<Bold>
							{" "}
							Sou a assistente virtual da Defensoria Pública do Estado do Acre.{" "}
						</Bold>
						Vamos fazer o seu cadastro, preencha as informações ao lado, elas
						serão usadas no seu atendimento. Caso tenha dificuldades para
						inserir as informações peça ajuda a um parente ou amigo.
					</Text>{" "}
				</ChatMessage>{" "}
				<ChatMessage>
          <Text>
		 Quero informar que o horário de acesso ao atendimento no chat é de 7h às 12h, conforme Resolução Nº 004/2021/GAB/DPE/AC.
          </Text>{" "}
        </ChatMessage>{" "}
			</>
		);
	}

	function Segundo() {
		return (
			<>
				<ChatMessage>
					<Text>
						Ola, Para ser atendido na Defensoria Pública, sua renda não deve
						ultrapassar 4 salários minimos, ou seja sua renda não pode ser maior
						do que R$ 4.216,00 (quatro mil duzentos e dezesseis reais)
					</Text>
				</ChatMessage>
			</>
		);
	}
	function Terceiro() {
		return (
			<>
				<ChatMessage>
					<Text>
						Agora preciso das suas informações pessoais, esses dados ficarão
						protegidos em nosso banco de dados. Iremos precisar deles para fazer
						a sua petição!{" "}
					</Text>
				</ChatMessage>
			</>
		);
	}

	function setPrimeiro() {
		setComp([...comps, <Primeiro />]);
	}
	let { step } = state.data;
	return (
		<Container fluid="true" style={{ height: '100vh'}}>
			<Row className='m-0' style={{ height: '100vh'}}>
			<Col md={5} lg={5} >
		{step === 0 && location.pathname === "/treinamento" ? ( <NomeSocial3/>) :(false ) }
        {step === 0 && location.pathname === "/itinerante" ? (  window.location.href = "https://atendimento.ac.def.br/atendimento") :(false ) }
        {step === 0 && location.pathname === "/atendimento" ? ( <NomeSocial/>) :( false) }
				{/* {step === 0 && <NomeSocial />} */}
			</Col>
			<Col md={7} lg={7} className='p-0'>
			<BotContainer>
				<Header>
					<img
						src={Logo}
						alt="Logo da Defensoria"
						width="230"
						style={{ marginRight: 100 }}
					/>
				</Header>
				{comps.map((comp,index) => {
					return(
            <div key={index}>
            {comp}
            </div>
            );
				})}
			</BotContainer>
			</Col>
			</Row>
		</Container>
	);
}
