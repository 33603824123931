import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import Depart from './departamentos.js';
import Lista_motivos from './lista_motivos.js';
// folha de estilos
import { Alert, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { AButton } from "../styles";

let agora = moment().tz("America/Rio_Branco").format();
var data_agora = moment().format("YYYY-MM-DD");
var extra = moment().tz("America/Rio_Branco").format("YYYY-MM-DD") + " ";
var start_time = moment(extra + "07:00").tz("America/Rio_Branco").format();
var end_time = moment(extra + "12:00").tz("America/Rio_Branco").format();
var feriado = moment('2024-08-06').format("YYYY-MM-DD");
export default function NomeSocial() {
  //const [validated, setValidated] = useState(false);
  const [Departamentos, setDepartamentos] = useState(Depart);
  const [form, setForm] = useState([]);
  const [Ecapital, setEcapital] = useState('Todos');
  const [Motivo, setMotivo] = useState('Todos');
  const [Seconsulta, setSeconsulta] = useState(true);
  const [Sevara, setSevara] = useState(2);
  const [Buscadepartamentoestado, setBuscadepartamentoestado] = useState(false);
  const [Desabilitado, setDesabilitado] = useState(false);

  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);


  const onChange = (field) => (res) => {
    setForm({
      ...form,
      [field]: res.target.value,
    });
    if (field === 'local_atendimento') {
      res.target.value === '' ? setEcapital('Todos') : setEcapital(res.target.value)
    }
    if (field === 'motivo_contato') {
      res.target.value === '' ? setMotivo('Todos') : setMotivo(res.target.value)
      res.target.value === 'consulta_processo_civil' || res.target.value === 'consulta_processo_criminal' ? setSeconsulta(false) : setSeconsulta(true)
      res.target.value === 'consulta_processo_civil' || res.target.value === 'consulta_processo_criminal' ? setSevara(1) : setSevara(2)
      res.target.value === 'primeiro_atendimento_civil' || res.target.value === 'primeiro_atendimento_criminal' ? setSevara(0) : setSevara(2)
      res.target.value === 'Hipossuficiência' && mensagemhipo()
      res.target.value === 'Documentos' && mensagemdocs()
      res.target.value === 'Emergencial' && mensagemoutros("Auxílio Emergencial")
      res.target.value === 'Diversos' && mensagemoutros2("Questões Trabalhistas, Processos Federais, Processos Eleitorais  ")

      res.target.value === 'Regime_Fechado' ? setBuscadepartamentoestado(true) : setBuscadepartamentoestado(false)
    }
    if (field === 'se_vara') {
      res.target.value === "true" ? setSevara(1) : setSevara(0)
    }
    if (field === 'departamento') {
      res.target.value === 'FN4EvMH7smX9Mudsi' && mensagem_sem_atendimento()
      res.target.value === 'THz7AKjiFxd3xEMmx' && mensagem_sem_atendimento()
      // res.target.value === '5PfCaWtnr8WgoNy6A' && mensagemVaraDeFamilia()
      // res.target.value === 'ebcYN4iHJmcrKAnKv' && mensagemVaraDeFamilia()
    }
  };

  const handleChange = () => {
    setChecked(!checked);
    setForm({
      ...form,
      ['termo1']: !checked,
    });

  };
  const handleChange2 = () => {
    setChecked2(!checked2);
    setForm({
      ...form,
      ['termo2']: !checked2,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // verifica se ainda esta dentro do horário de atendimento
    const data = form
    console.log(data)
    if (data.termo1 === true || data.termo2 === true) {
      const hoje = moment().tz("America/Rio_Branco").format();
      const hora_final = moment().tz("America/Rio_Branco").format("YYYY-MM-DD") + " ";
      const time_hora_final = moment(hora_final + "11:00").tz("America/Rio_Branco").format();
      if (hoje <= time_hora_final) {
        window.RocketChat(function () {
          this.setGuestName(data.nome);
          this.setCustomField("Telefone", data.telefone);
          this.setCustomField("Comarca", data.local_atendimento);
          this.setCustomField("cpf", data.cpf);
          this.setCustomField("data_nascimento", data.data_nascimento);
          this.setCustomField('termo1', 'Assistido Aceitou o termo: "Compreendo e aceito os termo de uso e a politica de privacidade da defensoria pública do Acre"');
          this.setCustomField('segundotermo', 'Assistido Aceitou o termo: "Autorizo a coleta de dados pessoais necessários para o atendimento."');
          const processo = data.tem_processo === '' ? 'Não informado' : data.tem_processo
          this.setCustomField("processo", processo);
          this.setCustomField("motivo", data.motivo_contato);
          const d_nome = findDepartment(data.departamento);
          console.log(d_nome)
          this.setCustomField("nome_departamento", d_nome.name);
          this.setDepartment(data.departamento);
          this.showWidget();
          this.maximizeWidget();
        });
        setDesabilitado(true);
      } else {
        // caso endentifique se ja passou o horário de atendimento
        Swal.fire({
          title: "Aviso",
          text: "A Defensoria Pública do Estado do Acre (DPE) informa que o horário de acesso ao atendimento no chat é de segunda a sexta-feira das 7h às 12h, exceto feriados, conforme Resolução n.º 004/2021/GAB/DPE/AC.",
          icon: "warning",
          confirmButtonText: "OK",
          position: "center",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "https://defensoria.ac.def.br/";
          } else {
          }
        });
        setDesabilitado(true)
      }

    } else {

      avisotermo()
    }
  };


  useEffect(() => {
    //console.log(id);
    //	console.log("useeffect");
    window.RocketChat(function () {
      const status = sessionStorage.getItem("@dpe:chat-status");
      const ini = sessionStorage.getItem("@dpe:data_inicio");
      if (status === "em-conversa" && moment(ini).isSame(agora, "day")) {
        //	console.log(status);
        this.showWidget();
        this.maximizeWidget();
        setDesabilitado(true);

      } else {
        this.hideWidget();
        this.minimizeWidget();
        setDesabilitado(false);
        sessionStorage.removeItem("@dpe:chat-status");
        sessionStorage.removeItem("@dpe:data_inicio");

      }
      //this.maximizeWidget();
      //this.hideWidget();
      //this.minimizeWidget();
      //this.setDepartment(id);
    });

    //axios.get("https://chat.defensoria.ac.def.br/api/v1/livechat/department/?count=0", config).then((resp) => setDepartamentos(resp.data.departments));

  }, []);


  function findDepartment(id) {
    return Departamentos.find((department) => department._id === id);
  }

  // mensagem dos motivos
  const mensagemhipo = (index) => {

    Swal.fire({
      title: "Atenção",
      text: "Vemos que você esta procurando a declaração de hipossuficiência! Para agilizar o atendimento temos o modelo pronto para preenchimento.Clique em 'Donwload Hipossuficiência' para baixar o documento. ",
      icon: "info",
      confirmButtonText: '<a href="https://defensoria.ac.def.br/back-end/img/c7bd2e6222ef2cb54bb3b429ae9c2909.docx" style="color:white !important">Donwload Hipossuficiência</a>',
      showCancelButton: true,
      cancelButtonText: 'Fechar',
      position: 'center'
    })

  }
  const avisotermo = (index) => {

    Swal.fire({
      title: "Aviso",
      text: "Você tem que concordar com nossos termos de uso e politicas de privacidade, além de autorizar a coleta e o uso de dados nescessário para o atendimento. ",
      icon: "warning",
      confirmButtonText: 'OK',
      position: 'center'
    })

  }
  const mensagemdocs = (index) => {

    Swal.fire({
      title: "Atenção",
      text: "Vemos que você esta procurando sobre os documentos nescessários para entrar com uma ação! Para agilizar o atendimento temos essas informações prontas para você. Clique em 'Documentos Necessários' para ser redireciondo para pagina de download. ",
      icon: "info",
      confirmButtonText: "Documentos Necessários",
      showCancelButton: true,
      cancelButtonText: 'Fechar',
      position: 'center'
    })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.href = "https://defensoria.ac.def.br/documentosTodos.php";
        } else {


        }
      })

  }

  const mensagemoutros = (index) => {

    Swal.fire({
      title: "Atenção",
      html: 'Informamos que não realizamos atendimentos referente ao "' + index + '". Informamos que esse atendimento e de responsabilidade da DPU( Defensoria pública da união) que pode ser acessado cliquando <a href="https://www.dpu.def.br/endereco-acre">aqui</a> ',
      icon: "info",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: 'Fechar',
      position: 'center'
    })

  }
  const mensagemoutros2 = (index) => {

    Swal.fire({
      title: "Atenção",
      html: 'Informamos que não realizamos atendimentos referente a "' + index + '".',
      icon: "info",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: 'Fechar',
      position: 'center'
    })

  }
  const mensagem_sem_atendimento = (index) => {

    Swal.fire({
      title: "Atenção",
      html: 'Informamos que o atendimento para essa defensoria somente pode ser realizado através do agendamento para atendimento presencial que pode ser acessado clicando <a href="https://agendamento.ac.def.br">aqui</a>. Ou pelo WHATSAPP (68) 99948-3648 ',
      icon: "info",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: 'Fechar',
      position: 'center'
    })

  }
  const mensagemVaraDeFamilia = (index) => {

    Swal.fire({
      title: "Atenção",
      html: `Querido usuário, <b>informamos</b> 
      que os atendimentos neste canal estarão suspensos no período de <b>15 a 19 de agosto de 2022</b>, em razão da <b>“XXI Semana pela Paz no Lar”</b>, junto a Vara de Proteção à Mulher.`,
      icon: "info",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: 'Fechar',
      position: 'center'
    })

  }
  // faz o filtro do campo departamentos
  const option_departamentos = (depart, index) => {

    if (Buscadepartamentoestado === false) {
      return (
        depart._id !== "zMNZuSkzwT494tbvq" && depart.capital.includes(Ecapital) === true && depart.motivo.includes(Motivo) === true && depart.vara.includes(Sevara) === true ? (
          <option key={index} value={depart._id}>
            {depart.name}
          </option>
        ) : null
      )
    } else {
      return (
        depart._id !== "zMNZuSkzwT494tbvq" && depart.capital.includes("Rio_Branco") === true && depart.motivo.includes(Motivo) === true && depart.vara.includes(Sevara) === true ? (
          <option key={index} value={depart._id}>
            {depart.name}
          </option>
        ) : null
      )
    }
  }
  const option_motivos = (motivo, index) => {

    return (

      <option key={index} value={motivo.value}>
        {motivo.name}
      </option>

    )
  }


  window.RocketChat(function () {
    this.onChatStarted(function () {
      const d_i = moment().tz("America/Rio_Branco").format("YYYY-MM-DD");
      sessionStorage.setItem("@dpe:data_inicio", d_i);
      // verifica se ainda esta dentro do horário de atendimento

      const hoje2 = moment().tz("America/Rio_Branco").format();
      const hora_final2 = moment().tz("America/Rio_Branco").format("YYYY-MM-DD") + " ";
      const time_hora_final2 = moment(hora_final2 + "11:00").tz("America/Rio_Branco").format();;

      if (hoje2 > time_hora_final2) {
        sessionStorage.removeItem("@dpe:chat-status");
        sessionStorage.removeItem("@dpe:data_inicio");

        Swal.fire({
          title: "Aviso",
          text:
            "A Defensoria Pública do Estado do Acre (DPE) informa que o horário de acesso ao atendimento no chat é de segunda a sexta-feira das 7h às 12h, exceto feriados, conforme Resolução n.º 004/2021/GAB/DPE/AC.",
          icon: "warning",
          confirmButtonText: "OK",
          position: "center",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "https://defensoria.ac.def.br/";
          } else {
          }
        });
        setDesabilitado(true)

      }
    });
  });

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} className="pt-4">
            <Row className="text-center mb-3">
              <Col xs={12}>
                <img
                  src="./it_logo.png"
                  className="img-fluid"
                  alt=""
                  style={{ "width": "50%" }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                {agora >= start_time &&
                  agora <= end_time &&
                  data_agora > feriado ? (
                  <>
                    <br></br>
                    <h2 style={{ "textAlign": "center" }}>Informações para o atendimento</h2>
                    <br></br>
                  </>
                ) : (
                  <>
                    <br></br><br></br>
                    <Alert color="danger">
                      <h4 className="alert-heading">
                        Atenção!
                      </h4>
                      <p>
                        A Defensoria Pública informa que o horário de atendimento é de segunda a sexta-feira das 7 horas às 12 horas, exceto feriados.   </p>
                    </Alert>
                    {/* <Alert color="danger">
                      <h4 className="alert-heading text-center">
                        Atenção!
                      </h4>
                      <p className="text-start">
                      Defensoria Pública do Acre comunica que, em razão do recesso forense o atendimento ao público no âmbito da DPE/AC(capital e interior) ficará suspenso a partir do dia 16 de dezembro de 2022 a 9 de janeiro 2023. Ressaltamos que nesse período as matérias de urgência serão atendidas pelo regime de plantão.
                        <br></br>
                          </p>
                          <p className="text-center">Desejamos a todos os mais sinceros votos de paz,saúde e prosperidade
</p>
                    </Alert> */}
                  </>
                )}

                <Form onSubmit={handleSubmit}>
                  {agora >= start_time &&
                    agora <= end_time &&
                    data_agora > feriado ? (
                    <>
                      <FormGroup className="mb-2">
                        <Label for="">Nome Completo: </Label>
                        <Input
                          type="text"
                          name="nome"
                          value={form.nome || ""}
                          onChange={onChange("nome")}
                          required
                          placeholder="Escreva seu nome completo"
                        />
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <Label for="">Informe sua data de Nascimento: </Label>
                        <InputMask
                          className="form-control"
                          type="text"
                          name="data_nascimento"
                          value={form.data_nascimento || ""}
                          onChange={onChange("data_nascimento")}
                          required
                          placeholder="Data nascimento ex: 10/10/1993"
                          mask="99/99/9999"
                          maskPlaceholder={null}
                        />
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <Label for="">CPF: </Label>
                        <InputMask
                          className="form-control"
                          type="text"
                          name="cpf"
                          value={form.cpf || ""}
                          onChange={onChange("cpf")}
                          required
                          placeholder="CPF (somente Números)"
                          mask="99999999999"
                          maskPlaceholder={null}
                        />
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <Label for="">Telefone: </Label>
                        <InputMask
                          className="form-control"
                          type="text"
                          name="telefone"
                          value={form.telefone || ""}
                          onChange={onChange("telefone")}
                          required
                          placeholder="Telefone (somente Números) ex: 68-999995555"
                          mask="99-999999999"
                          maskPlaceholder={null}
                        />
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <Label for="">Município em que você mora?</Label>
                        <Input
                          type="select"
                          value={form.local_atendimento || ""}
                          onChange={onChange("local_atendimento")}
                          name="local_atendimento"
                          required
                        >
                          <option value="">Escolha um Local</option>
                          <option value="Acrelândia">Acrelândia</option>
                          <option value="Assis_Brasil">Assis Brasil</option>
                          <option value="Brasiléia">Brasiléia</option>
                          {/* <option value="Bujari">Bujari</option> */}
                          <option value="Capixaba">Capixaba</option>
                          <option value="Cruzeiro_do_Sul">
                            Cruzeiro do Sul
                          </option>
                          {/* <option value="Epitaciolândia">Epitaciolândia</option> */}
                          {/* <option value="Feijó">Feijó</option> */}
                          {/* <option value="Jordão">Jordão</option> */}
                          <option value="Mâncio_Lima">Mâncio Lima</option>
                          <option value="Manoel_Urbano">Manoel Urbano</option>
                          {/* <option value="Marechal_Thaumaturgo">Marechal Thaumaturgo</option> */}
                          <option value="Plácido_de_Castro">
                            Plácido de Castro
                          </option>
                          <option value="Porto_Acre">Porto Acre</option>
                          {/* <option value="Porto_Walter">Porto Walter</option> */}
                          <option value="Rio_Branco">Rio Branco</option>
                          {/* <option value="Rodrigues_Alves">
              Rodrigues Alves
              </option> */}
                          {/* <option value="Santa_Rosa_do_Purus">Santa Rosa do Purus</option> */}
                          {/* <option value="Sena_Madureira">Sena Madureira</option> */}
                          <option value="Senador_Guiomard">
                            Senador Guiomard
                          </option>
                          <option value="Tarauacá">Tarauacá</option>
                          {/* <option value="Xapuri">Xapuri</option> */}
                        </Input>
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <Label for="">Existe processo em andamento, se sim digite o número correspondente?</Label>
                        <Input
                          type="number"
                          name="tem_processo"
                          value={form.tem_processo || ""}
                          onChange={onChange("tem_processo")}

                          placeholder="Digite o número do processo (se houver)"
                        />
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <Label for="select_qualmotivo">
                          Qual o motivo do seu contato?
                        </Label>
                        <Input
                          type="select"
                          value={form.motivo_contato || ""}
                          onChange={onChange("motivo_contato")}
                          name="motivo_contato"
                          required
                          id="select_qualmotivo"
                        >
                          <option value="">
                            Escolha um Motivo de contato
                          </option>
                          {Lista_motivos.sort(function (a, b) {

                            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);

                          }).map(option_motivos)}
                        </Input>
                      </FormGroup>
                      <FormGroup hidden={Seconsulta}>
                        <Label for="se_vara">
                          Você sabe em qual vara se encontra seu processo?
                        </Label><br></br>
                        <FormGroup check inline  >
                          <Label check >
                            <Input type="radio" disabled={Seconsulta} id="se_vara" name="se_vara" onChange={onChange("se_vara")} value="true" required /> Sim
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input type="radio" name="se_vara" disabled={Seconsulta} onChange={onChange("se_vara")} value="false" required /> Não
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <Label for="">Departamento: </Label>
                        <Input
                          type="select"
                          value={form.departamento || ""}
                          onChange={onChange("departamento")}
                          name="departamento"
                          required
                        >
                          <option value="">Escolha um departamento</option>
                          {Departamentos.sort((a, b) => a.name == b.name ? 0 : a.name > b.name ? 1 : -1).map(option_departamentos)}
                        </Input>
                      </FormGroup>
                      <FormGroup row className="mt-4">
                        <Col sm={{ size: 10 }}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="checkbox2"
                                name="termo1"
                                value={checked}
                                onChange={handleChange}
                                required
                              />{" "}
                              <>
                                {" "}
                                Compreendo e aceito os{" "}
                                <a
                                  href="https://defensoria.ac.def.br/termo_de_uso.php"
                                  target="_blank"
                                >
                                  termo de uso
                                </a>{" "}
                                e a{" "}
                                <a
                                  href="https://defensoria.ac.def.br/politica_de_privacidade.php"
                                  target="_blank"
                                >
                                  politica de privacidade
                                </a>{" "}
                                da defensoria pública do Acre.
                              </>
                            </Label>
                          </FormGroup>
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col sm={{ size: 10 }}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="checkbox"
                                name="termo2"
                                value={checked2}
                                required
                                onChange={handleChange2}
                              />
                              Autorizo a coleta de dados pessoais necessários
                              para o atendimento.
                            </Label>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                      <AButton
                        type="submit"
                        disabled={Desabilitado}
                        id="btn_chat"
                      >
                        Abrir o Chat
                      </AButton>
                      {Desabilitado && (
                        <p>
                          Digite no menu chat para iniciar o atendimento!ou
                          encerre e atualize a pagina para iniciar um novo
                          atendimento!
                        </p>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
